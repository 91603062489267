import type { ButtonProps } from '@lemonenergy/lemonpie-components'
import { Button } from '@lemonenergy/lemonpie-components'
import { Whatsapp } from '@lemonenergy/lemonpie-icons'

import { useRootLoaderData } from '~/hooks/useRootLoaderData'
import useWhatsapp from '~/hooks/useWhatsapp'

type WhatsappButtonProps = ButtonProps & {
  message?: string
  whatsappNumber?: string
}

const WhatsappButton = ({
  message = '',
  whatsappNumber,
  onClick,
  variant,
  ...props
}: WhatsappButtonProps) => {
  const rootLoaderData = useRootLoaderData()

  const whatsapp = useWhatsapp(
    whatsappNumber ?? rootLoaderData?.whatsappNumber ?? '',
  )

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event)

    whatsapp.open(message)
  }

  return (
    <Button variant={variant || 'primary'} onClick={handleClick} {...props}>
      <Whatsapp className="mr-smallest" /> Falar com a gente
    </Button>
  )
}

export default WhatsappButton
